<template>
  <section class="dtc-main-section mt-2 mr-2">
    <RuralNursingDataGraduScoreAllWatch></RuralNursingDataGraduScoreAllWatch>
    <section class="search-block">
      <h5 class="big-title">偏護菁英-當年度畢業成績檢視</h5>
      <div class="dtc-search">
        <b-input-group prepend="畢業年">
          <b-input
            v-model.trim="searchEndYear"
            placeholder="請輸入畢業年"
            @keydown.enter="searchDb"
          ></b-input>
        </b-input-group>
        <b-button variant="primary" @click="calcuateScore"
          >年度成績計算</b-button
        >
        <span class="mt-2"
          >{{ noScoreYear }}年度 共有<span style="color:red">{{
            noScorePeopleNum
          }}</span
          >位畢業成績未填寫</span
        >
      </div>
    </section>
    <header class="dtc-grid-header my-dark">
      <div
        v-for="(item, i) in headers"
        :key="`headers${i}`"
        :title="item.name || item"
        @click="sort(item)"
      >
        {{ item.name || item }}
        <span v-show="item.sortDesc === null">
          <i class="fas fa-sort"></i>
        </span>
        <span v-show="item.sortDesc === false">
          <i class="fas fa-sort-up"></i>
        </span>
        <span v-show="item.sortDesc">
          <i class="fas fa-sort-down"></i>
        </span>
      </div>
    </header>
    <main
      class="dtc-grid-header2"
      v-for="(item, i) in items"
      :key="i"
      :style="
        i % 2 == 0 ? 'background-color: #EDEDED;' : 'background-color: #DDDDDD;'
      "
    >
      <div>
        {{ i + 1 }}
      </div>
      <div
        style="cursor: pointer;font-size: 1.6rem;padding-top:0px;"
        @click="editLocalData(item)"
      >
        <i class="fas fa-search-plus"></i>
      </div>
      <div :title="item.YearTerm">
        {{ item.YearTerm }}
      </div>
      <div :title="item.Name">
        {{ item.Name || "暫無資料" }}
      </div>
      <div :title="item.Identifier">
        {{ item.Identifier || "暫無資料" }}
      </div>
      <!-- <div :title="item.gender">
        {{ item.gender }}
      </div>
      <div :title="item.birthday">
        {{ item.birthday }}
      </div> -->
      <!-- <div :title="item.hometown">
        {{ item.hometown }}
      </div> -->
      <div :title="item.SchoolName">{{ item.SchoolName || "暫無資料" }}</div>
      <div :title="item.Rank">
        {{ item.Rank }}
      </div>
      <div :title="item.Result">
        {{ item.Result }}
      </div>
      <div :title="item.Score">
        {{ item.Score }}
      </div>
      <div :title="item.MaxScore">
        {{ item.MaxScore }}
      </div>
      <div :title="item.MinScore">
        {{ item.MinScore }}
      </div>

      <div :title="item.ScoreSubMin">
        {{ item.ScoreSubMin }}
      </div>
      <div :title="item.MaxSubMin">{{ item.MaxSubMin }}</div>
      <div
        :title="item.IdentityCheck"
        :style="item.IdentityCheck == '未提供' ? 'color:red' : ''"
      >
        {{ item.IdentityCheck || "暫無資料" }}
      </div>
      <div
        :title="item.VeteranCheck"
        :style="item.VeteranCheck == '未提供' ? 'color:red' : ''"
      >
        {{ item.VeteranCheck || "暫無資料" }}
      </div>
      <div
        :title="item.TranscriptCheck"
        :style="item.TranscriptCheck == '未提供' ? 'color:red' : ''"
      >
        {{ item.TranscriptCheck || "暫無資料" }}
      </div>
    </main>
    <footer class="dtx-footer">
      <b-pagination
        v-model="currentPageNum"
        :total-rows="rowCount"
        :per-page="pagingRowPerPage"
        aria-controls="dataTable1"
        align="center"
        class="mt-2"
      >
        <span slot="first-text">
          <i class="fas fa-fast-backward"></i>
        </span>
        <span slot="prev-text">
          <i class="fas fa-step-backward"></i>
        </span>
        <span slot="next-text">
          <i class="fas fa-step-forward"></i>
        </span>
        <span slot="last-text">
          <i class="fas fa-fast-forward"></i>
        </span>
        <span slot="page" slot-scope="{ page, active }">
          <b v-if="active">{{ page }}</b>
          <i v-else>{{ page }}</i>
        </span>
      </b-pagination>
      <b-select
        :options="rows"
        class="mt-2"
        v-model="pagingRowPerPage"
      ></b-select>
      <div class="mt-3">{{ totalCountStr }}</div>
    </footer>
  </section>
</template>

<script>
import queryString from "query-string";
import buildQuery from "odata-query";
import { store } from "@/store/global.js";
import RuralNursingDataGraduScoreAllWatch from "./RuralNursingDataGraduScoreAllWatch";

const headers = [
  "序",
  "檢視",
  "畢業年",
  "姓名",
  "身分證字號",
  // "性別",
  // "出生日期",
  // "籍貫",
  "培育學校",
  // "級分排名",
  // "個人級分",
  // "個人成績",
  { name: "級分排名", key: "Rank", sortDesc: null },
  { name: "個人級分", key: "Result", sortDesc: null },
  { name: "個人成績", key: "Score", sortDesc: null },
  { name: "同屆最高成績", key: "MaxScore", sortDesc: null },
  { name: "同屆最低成績", key: "MinScore", sortDesc: null },
  // "同屆最高成績",
  // "同屆最低成績",
  "個人成績-同屆最低成績",
  "同屆最高成績-同屆最低成績",
  "身分證正反影本",
  "兵役文件",
  "成績單文件",
];
const zero = "T00:00:00";
const rows = [10, 20, 50];
const year = new Date().getFullYear() - 1911;
let years = new Array(8).fill().map((s, i) => year + 1 - i);
years.unshift({ value: null, text: "全部" });

export default {
  name: "downarea",
  data() {
    return {
      headers,
      items: [],
      keys: [],
      orderBy: [],
      currentPageNum: 1,
      rowCount: 0,
      pagingRowPerPage: 20,
      year: null,
      search: true,
      rows,
      totalCountStr: "",
      years,
      searchEndYear: new Date().getFullYear() - 1911,
      noScorePeopleNum: 0,
      noScoreYear: "",
    };
  },
  components: { RuralNursingDataGraduScoreAllWatch },
  computed: {},

  methods: {
    async getNoScoreData() {
      this.noScorePeopleNum = 0;
      // let { Items } = await window.axios.get(
      //   `NurseDistribution/GetGraduationResults?$filter=YearTerm eq '${this.searchEndYear}'`
      // );
      let number = await window.axios.get(
        `NurseDistribution/GetNoScore?year=${this.searchEndYear}`
      );

      // const arr = Items.filter((s) => {
      //   return s.Score >= 0 || s.MaxScore >= 0 || s.MinScore >= 0;
      // });
      // this.noScorePeopleNum = +Items.length - +arr.length;
      this.noScorePeopleNum = number;
      this.noScoreYear = this.searchEndYear;
    },
    async calcuateScore() {
      if (!Boolean(this.searchEndYear)) {
        this.$bvToast.toast(`請確實填寫年度`, {
          title: "年度成績計算失敗!",
          autoHideDelay: 5000,
          variant: "danger",
        });
        return;
      } else {
        try {
          await window.axios.get(
            `NurseDistribution/GetCalculation?year=${this.searchEndYear}`
          );
          this.$bvToast.toast(`計算成功!`, {
            title: "年度成績",
            autoHideDelay: 5000,
            variant: "success",
          });
          this.searchDb();
          this.getNoScoreData();
        } catch (error) {
          this.$bvToast.toast(`計算失敗!` + error, {
            title: "年度成績",
            autoHideDelay: 5000,
            variant: "danger",
          });
        }
      }
    },
    sort(item) {
      if (!Boolean(item.name)) {
        return;
      }
      if (item.sortDesc) {
        item.sortDesc = null;
      } else if (false === item.sortDesc) {
        item.sortDesc = true;
      } else if (null === item.sortDesc) {
        item.sortDesc = false;
      }
      this.orderBy = [];
      this.headers.forEach((s) => {
        if (!Boolean(s.name)) {
          return;
        } else if (s.sortDesc !== null) {
          this.orderBy.push(s.sortDesc ? `${s.key} desc` : `${s.key}`);
        }
      });
      this.getData();
    },
    editLocalData(item) {
      store.editItem = { ...item };
      store.editItem.tab = 2;
      this.$bvModal.show("ruralNursingDataGraduScoreAllWatch");
    },

    searchDb() {
      this.search = true;
      this.getData();
    },
    async clearSearch() {
      this.searchEndYear = new Date().getFullYear() - 1911;
      this.getData();
    },
    async getData() {
      const isOrderRank = this.orderBy.find((s) => s.includes("Rank"))
        ? true
        : false;
      if (!isOrderRank) {
        this.orderBy.unshift("Rank");
      }
      //odata3 qs
      const page = this.currentPageNum;
      const skip = (page - 1) * this.pagingRowPerPage;
      const top = this.pagingRowPerPage;
      const orderBy = this.orderBy;
      const obj = { top, skip, orderBy };
      let qs = buildQuery(obj); // + no + filter + filter2;
      // qs = `${qs}&$filter=Category eq '2'`;

      if (this.searchEndYear) {
        qs = this.$equalFilter("YearTerm", qs, this.searchEndYear);
      }

      try {
        let { Items, Count } = await window.axios.get(
          `NurseDistribution/GetGraduationResults${qs}`
        );

        const millitaryOptions = [
          { value: "A", text: "未服役" },
          { value: "B", text: "退伍" },
          { value: "C", text: "免役" },
          { value: "D", text: "女性免役" },
        ];
        Items = Items.map((s) => {
          s.veteranStatus = millitaryOptions.find(
            (k) => k.value == s.VeteranStatus
          )
            ? millitaryOptions.find((k) => k.value == s.VeteranStatus).text
            : "";
          return s;
        });
        this.items = Items;

        this.items = Items.map((s) => {
          s.scoreSubHigh = (+s.Score - +s.MaxScore).toFixed(2);
          // s.type = +s.Category || "";
          // s.name = s.Name || "";
          // s.id = s.Identifier || "";
          // s.gender = !Boolean(s.Gender) ? "" : s.Gender == "M" ? "男" : "女";
          // s.school = this.schoolObject.find((k) => +k.value == +s.SchoolCode)
          //   ? this.schoolObject.find((k) => +k.value == +s.SchoolCode).text
          //   : "";
          // s.hometown = this.hometownObject.find((k) => k.value == +s.County)
          //   ? this.hometownObject.find((k) => k.value == +s.County).text
          //   : "";
          return s;
        });

        this.rowCount = Count;
        this.totalCountStr = `共${Count} 筆`;
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
  },
  async mounted() {
    this.calcuateScore();
  },

  watch: {
    currentPageNum(v) {
      this.currentPageNum = v;
      this.getData().catch((e) => {});
    },
    pagingRowPerPage(v) {
      this.getData().catch((e) => {});
    },
    searchEndYear(v) {
      if (+v < 0) {
        this.searchEndYear = "0";
      }
      let value = v;
      if (value.substring(0, 1) == "0") {
        value = value.substring(1);
      }
      if (v.length > 3) {
        value = value.substring(1, 4);
      }
      const result = value;
      this.$nextTick(() => (this.searchEndYear = result));
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dtc-main-section {
  // overflow-x: auto;
  padding: 12px;
  // position: relative;
  // height: 98vh;
}
.dtc-grid-header,
.dtc-grid-header2 {
  display: grid;
  grid-template-columns: 80px 80px repeat(3, 120px) 200px repeat(5, 120px) 200px 220px 120px 160px 160px;
  grid-auto-flow: column;
  text-align: center;
  border-right: 0px;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    /* word-break: keep-all; */
    -webkit-box-orient: vertical;
    font-size: 14px;
    padding: 4px 0;
    border-right: 1px solid #dee2e5;
    border-bottom: 1px solid #dee2e5;
    display: -webkit-box;
    height: 36px;
  }
  > div:first-child {
    border-left: 1px solid #dee2e5;
  }
  > div:last-child {
    // border-right: none;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 6px;
    padding-top: 7px;
  }
}

.my-dark {
  > div {
    // background: var(--gray);
    background: #424242;
    color: white;
  }
}
.dtc-link {
  color: var(--primary);
  cursor: pointer;
}
.dtc-search {
  display: grid;
  max-height: 40px;
  margin-bottom: 12px;
  grid-template-columns: repeat(34, max-content);
  grid-gap: 12px;
  > * {
    max-height: 44px;
  }
}
.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}

.input-group-text {
  width: 120px;
  background: #0379fd;
  color: #fff;
}
.file-content {
  text-align: start;
  padding-left: 10px !important;
  .subject {
    cursor: pointer;
    font-weight: 900;
  }
  .content {
    color: #777;
  }
}
.search-block {
  // height: 190px;
  margin-bottom: 10px;
  .big-title {
    background: #0379fd;
    padding: 5px;
    color: #fff;
    border-radius: 6px 6px 0 0;
  }
  .dtc-search,
  .dtc-search1 {
    padding-left: 10px;
  }
  background: #fbfcf9;
  border: 1px solid #bdbdbd;
  border-radius: 8px 8px 0 0;
}
</style>
