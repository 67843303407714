<template>
  <section class="dtc-main-section mt-2 mr-2">
    <RuralNursingDistributionWatch></RuralNursingDistributionWatch>
    <section class="search-block">
      <h5 class="big-title">偏護菁英-當年度分發結果</h5>
      <div class="dtc-search">
        <b-input-group prepend="畢業年">
          <b-input
            v-model.trim="searchEndYear"
            placeholder="請輸入畢業年"
            @keydown.enter="searchDb"
          ></b-input>
        </b-input-group>
        <b-button
          variant="warning"
          @click="calculateResult"
          v-show="!loadingCalcul"
          >計算分發結果</b-button
        >
        <b-button variant="warning" disabled v-show="loadingCalcul">
          <b-spinner small type="grow"></b-spinner>
          Loading...
        </b-button>
        <div>
          <b-button variant="primary" @click="openFile2"
            >匯出-志願分發結果總表</b-button
          >
        </div>

        <b-button variant="success" @click="openFile1" v-show="!loadingFile1"
          >匯出-醫院分發員額及志願表</b-button
        >
        <b-button variant="success" disabled v-show="loadingFile1">
          <b-spinner small type="grow"></b-spinner>
          Loading...
        </b-button>
      </div>
    </section>
    <header class="dtc-grid-header my-dark">
      <div
        v-for="(item, i) in headers"
        :key="`headers${i}`"
        :title="item.name || item"
        @click="sort(item)"
      >
        {{ item.name || item }}
        <span v-show="item.sortDesc === null">
          <i class="fas fa-sort"></i>
        </span>
        <span v-show="item.sortDesc === false">
          <i class="fas fa-sort-up"></i>
        </span>
        <span v-show="item.sortDesc">
          <i class="fas fa-sort-down"></i>
        </span>
      </div>
    </header>
    <main
      class="dtc-grid-header2"
      v-for="(item, i) in items"
      :key="i"
      :style="
        i % 2 == 0 ? 'background-color: #EDEDED;' : 'background-color: #DDDDDD;'
      "
    >
      <div>
        {{ i + 1 }}
      </div>
      <div
        style="cursor: pointer;font-size: 1.6rem;padding-top:0px;"
        @click="editLocalData(item)"
      >
        <i class="fas fa-search-plus"></i>
      </div>
      <div :title="item.YearTerm">
        {{ item.YearTerm || "" }}
      </div>
      <div :title="item.Name">
        {{ item.Name }}
      </div>
      <div :title="item.Identifier">
        {{ item.Identifier || "" }}
      </div>
      <div :title="item.HospitalCode">
        {{ item.HospitalCode || "" }}
      </div>
      <div :title="item.HospitalNo">
        {{ item.HospitalNo || "" }}
      </div>
      <div :title="item.HospitalName">
        {{ item.HospitalName || "" }}
      </div>
      <div :title="item.catName">
        {{ item.catName || "" }}
      </div>

      <div :title="item.CountryName">
        {{ item.CountryName || "" }}
      </div>
      <div :title="item.Approved">
        {{ item.Approved || "" }}
      </div>
      <div :title="`${item.ResultName}${item.Rank}`">
        {{ item.ResultName || "" }}{{ item.Rank }}
      </div>
      <!-- <div :title="item.Kind">{{ item.Kind || "" }}</div> -->
    </main>
    <footer class="dtx-footer">
      <b-pagination
        v-model="currentPageNum"
        :total-rows="rowCount"
        :per-page="pagingRowPerPage"
        aria-controls="dataTable1"
        align="center"
        class="mt-2"
      >
        <span slot="first-text">
          <i class="fas fa-fast-backward"></i>
        </span>
        <span slot="prev-text">
          <i class="fas fa-step-backward"></i>
        </span>
        <span slot="next-text">
          <i class="fas fa-step-forward"></i>
        </span>
        <span slot="last-text">
          <i class="fas fa-fast-forward"></i>
        </span>
        <span slot="page" slot-scope="{ page, active }">
          <b v-if="active">{{ page }}</b>
          <i v-else>{{ page }}</i>
        </span>
      </b-pagination>
      <b-select
        :options="rows"
        class="mt-2"
        v-model="pagingRowPerPage"
      ></b-select>
      <div class="mt-3">{{ totalCountStr }}</div>
    </footer>
  </section>
</template>

<script>
import queryString from "query-string";
import buildQuery from "odata-query";
import { store } from "@/store/global.js";
import moment from "moment";
import RuralNursingDistributionWatch from "./RuralNursingDistributionWatch";
import { domainObject } from "@/constant.js";

const headers = [
  "序",
  "檢視",
  "畢業年",
  // "姓名",
  // "身分證字號",
  // "醫事機構代碼",
  // "醫院代碼",
  // "醫院名稱",
  { name: "姓名", key: "Name", sortDesc: null },
  { name: "身分證字號", key: "Identifier", sortDesc: null },
  { name: "醫事機構代碼", key: "HospitalCode", sortDesc: null },
  { name: "醫院代碼", key: "HospitalNo", sortDesc: null },
  { name: "醫院名稱", key: "HospitalName", sortDesc: null },
  "醫院層級",
  "縣市別",
  "核定員額",
  "正取順序",
  // "三年內未考取護理師資格,醫院將以實習護士或照服員聘用意願",
];
const zero = "T00:00:00";
const rows = [10, 20, 50];
const year = new Date().getFullYear() - 1911;
let years = new Array(8).fill().map((s, i) => year + 1 - i);
years.unshift({ value: null, text: "全部" });

export default {
  name: "downarea",
  data() {
    return {
      headers,
      items: [],
      keys: [],
      currentPageNum: 1,
      rowCount: 0,
      pagingRowPerPage: 20,
      year: null,
      search: true,
      rows,
      totalCountStr: "",
      years,
      searchEndYear: new Date().getFullYear() - 1911,
      domainObject,
      loadingFile1: false,
      loadingCalcul: false,
      levelOptions: [],
    };
  },
  components: {
    RuralNursingDistributionWatch,
  },
  computed: {},

  methods: {
    sort(item) {
      if (!Boolean(item.name)) {
        return;
      }
      if (item.sortDesc) {
        item.sortDesc = null;
      } else if (false === item.sortDesc) {
        item.sortDesc = true;
      } else if (null === item.sortDesc) {
        item.sortDesc = false;
      }
      this.orderBy = [];
      this.headers.forEach((s) => {
        if (!Boolean(s.name)) {
          return;
        } else if (s.sortDesc !== null) {
          this.orderBy.push(s.sortDesc ? `${s.key} desc` : `${s.key}`);
        }
      });
      this.getData();
    },
    async openFile1() {
      if (!Boolean(this.searchEndYear)) {
        this.$bvToast.toast(`請確實填寫年度`, {
          title: "匯出失敗!",
          autoHideDelay: 5000,
          variant: "danger",
        });
        return;
      }
      try {
        const url = `NurseDistributionResult/GetExportTotalExcel?year=${this.searchEndYear}`;
        this.loadingFile1 = true;
        const res = await window.axios.get(url);
        const fullPath = `${this.domainObject.platformOriginal}${res}`;
        this.loadingFile1 = false;
        window.open(fullPath);
      } catch (error) {
        this.loadingFile1 = false;
        this.$bvToast.toast(`匯出失敗!` + error, {
          title: "志願分發結果總表",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async openFile2() {
      if (!Boolean(this.searchEndYear)) {
        this.$bvToast.toast(`請確實填寫年度`, {
          title: "匯出失敗!",
          autoHideDelay: 5000,
          variant: "danger",
        });
        return;
      }
      try {
        const url = `NurseDistributionResult/GetExportResult?year=${this.searchEndYear}`;
        const res = await window.axios.get(url);
        const fullPath = `${this.domainObject.platformOriginal}${res}`;
        window.open(fullPath);
      } catch (error) {
        this.$bvToast.toast(`匯出失敗!` + error, {
          title: "醫院分發員額及志願表",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async calculateResult() {
      if (!Boolean(this.searchEndYear)) {
        this.$bvToast.toast(`請確實填寫年度`, {
          title: "年度分發結果計算失敗!",
          autoHideDelay: 5000,
          variant: "danger",
        });
        return;
      } else {
        try {
          this.loadingCalcul = true;
          await window.axios.get(
            `NurseDistributionResult/GetCalculation?year=${this.searchEndYear}`
          );
          this.loadingCalcul = false;
          this.$bvToast.toast(`計算成功!`, {
            title: "年度分發結果",
            autoHideDelay: 5000,
            variant: "success",
          });
          this.searchDb();
        } catch (error) {
          this.$bvToast.toast(`計算失敗!` + error, {
            title: "年度分發結果",
            autoHideDelay: 5000,
            variant: "danger",
          });
        }
      }
    },
    editLocalData(item) {
      store.editItem = { ...item };
      store.editItem.tab = 3;
      this.$bvModal.show("ruralNursingDataGraduScoreAllWatch");
    },
    openfile(item) {
      if (!Boolean(item.FileLocation)) {
        this.$bvToast.toast(`下載專區`, {
          title: "暫無檔案!",
          autoHideDelay: 5000,
          variant: "danger",
        });
      } else {
        let domainName = document.domain;
        let fullPath = `api${item.FileLocation}`;
        window.open(fullPath);
      }
    },
    searchDb() {
      this.search = true;
      this.getData();
    },
    async clearSearch() {
      this.filename = "";
      this.getData();
    },
    async getData() {
      //odata3 qs
      const page = this.currentPageNum;
      const skip = (page - 1) * this.pagingRowPerPage;
      const top = this.pagingRowPerPage;
      const orderBy = this.orderBy;
      const obj = { top, skip, orderBy };
      let qs = buildQuery(obj); // + no + filter + filter2;
      // qs = `${qs}&$filter=Category eq '2'`;

      if (this.searchEndYear) {
        qs = this.$equalFilter("YearTerm", qs, this.searchEndYear);
      }

      try {
        let { Items, Count } = await window.axios.get(
          `NurseDistributionResult/Get${qs}`
        );

        const millitaryOptions = [
          { value: "A", text: "未服役" },
          { value: "B", text: "退伍" },
          { value: "C", text: "免役" },
          { value: "D", text: "女性免役" },
        ];
        Items = Items.map((s) => {
          s.veteranStatus = millitaryOptions.find(
            (k) => k.value == s.VeteranStatus
          )
            ? millitaryOptions.find((k) => k.value == s.VeteranStatus).text
            : "";
          return s;
        });
        this.items = Items;

        this.items = Items.map((s) => {
          s.scoreSubHigh = (+s.Score - +s.MaxScore).toFixed(2);
          s.catName = this.levelOptions.find((k) => k.value == s.Category)
            ? this.levelOptions.find((k) => k.value == s.Category).text
            : "";

          return s;
        });

        this.rowCount = Count;
        this.totalCountStr = `共${Count} 筆`;
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async getSearchOptions() {
      const arr = await window.axios.get("SysCode/Get?category=hc_level");
      this.levelOptions = arr.map((s) => ({
        value: s.Code,
        text: s.Display,
      }));
    },
  },
  async mounted() {
    await this.getSearchOptions();
    await this.calculateResult();
  },

  watch: {
    currentPageNum(v) {
      this.currentPageNum = v;
      this.getData().catch((e) => {});
    },
    pagingRowPerPage(v) {
      this.getData().catch((e) => {});
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dtc-main-section {
  //   overflow-x: auto;
  padding: 12px;
  //   position: relative;
  //   height: 98vh;
}
.dtc-grid-header,
.dtc-grid-header2 {
  display: grid;
  grid-template-columns: 80px repeat(6, 135px) 370px repeat(4, 135px);
  grid-auto-flow: column;
  text-align: center;
  border-right: 0px;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    /* word-break: keep-all; */
    -webkit-box-orient: vertical;
    font-size: 14px;
    padding: 4px 0;
    border-right: 1px solid #dee2e5;
    border-bottom: 1px solid #dee2e5;
    display: -webkit-box;
    height: 36px;
  }
  > div:first-child {
    border-left: 1px solid #dee2e5;
  }
  > div:last-child {
    // border-right: none;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 6px;
    padding-top: 7px;
  }
}

.my-dark {
  > div {
    // background: var(--gray);
    background: #424242;
    color: white;
  }
}
.dtc-link {
  color: var(--primary);
  cursor: pointer;
}
.dtc-search {
  display: grid;
  max-height: 40px;
  margin-bottom: 12px;
  grid-template-columns: repeat(34, max-content);
  grid-gap: 12px;
  > * {
    max-height: 44px;
  }
}
.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}

.input-group-text {
  width: 120px;
  background: #0379fd;
  color: #fff;
}
.file-content {
  text-align: start;
  padding-left: 10px !important;
  .subject {
    cursor: pointer;
    font-weight: 900;
  }
  .content {
    color: #777;
  }
}
.search-block {
  //   height: 190px;
  margin-bottom: 10px;
  .big-title {
    background: #0379fd;
    padding: 5px;
    color: #fff;
    border-radius: 6px 6px 0 0;
  }
  .dtc-search,
  .dtc-search1 {
    padding-left: 10px;
  }
  background: #fbfcf9;
  border: 1px solid #bdbdbd;
  border-radius: 8px 8px 0 0;
}
</style>
