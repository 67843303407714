<template>
  <b-modal
    @shown="updateItem"
    title="檢視偏護菁英畢業生基本資料"
    id="ruralNursingDataGraduScoreAllWatch"
    size="xl"
    @hide="doSometing"
  >
    <b-container fluid>
      <main class="main-grid-dtc">
        <b-input-group prepend="姓名">
          <b-input v-model="item.Name" key="name" readonly></b-input>
        </b-input-group>
        <b-input-group prepend="身分證字號">
          <b-input v-model="item.Identifier" readonly></b-input>
        </b-input-group>
        <b-input-group prepend="出生日期">
          <b-input v-model="item.Cht_BirthDate" readonly></b-input>
        </b-input-group>
        <b-input-group prepend="性別">
          <b-input v-model="item.gender" readonly></b-input>
        </b-input-group>
        <b-input-group prepend="培育學校">
          <b-input v-model="item.SchoolName" readonly></b-input>
        </b-input-group>
        <b-input-group prepend="入學年">
          <b-input v-model="item.StartYear" readonly></b-input>
        </b-input-group>
        <b-input-group prepend="畢業年">
          <b-input v-model="item.EndYear" readonly></b-input>
        </b-input-group>
        <b-input-group prepend="籍別">
          <b-input v-model="item.Country" readonly></b-input>
        </b-input-group>
        <b-input-group prepend="市話">
          <b-input v-model="item.Home" readonly></b-input>
        </b-input-group>
        <b-input-group prepend="行動電話">
          <b-input v-model="item.Mobile" readonly></b-input>
        </b-input-group>
        <b-input-group prepend="籍貫">
          <b-input v-model="item.home" readonly></b-input>
        </b-input-group>
        <b-input-group prepend="戶籍地址" style="grid-column: 1/-1;">
          <b-input v-model="item.ResidenceAddrs" readonly></b-input>
        </b-input-group>
        <b-input-group prepend="通訊地址" style="grid-column: 1/-1;">
          <b-input v-model="item.ContAddress" readonly></b-input>
        </b-input-group>
        <b-input-group prepend="E-MAIL" style="grid-column: 1/-1;">
          <b-input v-model="item.Email" readonly></b-input>
        </b-input-group>
      </main>
      <main class="main-grid-dtc2 mt-2">
        <div class="title mb-1" style="grid-column: 1/-1;">
          畢業成績資料
        </div>
        <b-input-group prepend="個人成績">
          <b-input v-model="item.Score" readonly></b-input>
        </b-input-group>
        <b-input-group prepend="同屆最高成績">
          <b-input v-model="item.MaxScore" readonly></b-input>
        </b-input-group>
        <b-input-group prepend="同屆最低成績">
          <b-input v-model="item.MinScore" readonly></b-input>
        </b-input-group>
        <b-input-group prepend="兵役狀況">
          <b-input v-model="item.VeteranStatus" readonly></b-input>
        </b-input-group>
      </main>
      <main class="main-grid-dtc2 mt-2">
        <div class="title mb-1" style="grid-column: 1/-1;">
          分發醫院相關文件資料
        </div>
        <b-input-group prepend="身分證影本">
          <b-input
            v-model="item.IdentityCheck"
            :style="item.IdentityCheck == '未提供' ? 'color:red' : ''"
            readonly
          ></b-input>
        </b-input-group>
        <b-input-group prepend="兵役文件">
          <b-input
            v-model="item.VeteranCheck"
            :style="item.VeteranCheck == '未提供' ? 'color:red' : ''"
            readonly
          ></b-input>
        </b-input-group>
        <b-input-group prepend="成績單">
          <b-input
            v-model="item.TranscriptCheck"
            :style="item.TranscriptCheck == '未提供' ? 'color:red' : ''"
            readonly
          ></b-input>
        </b-input-group>
      </main>
      <hr class="my-2 new" />
      <main class="main-grid-dtc2 mt-2" v-if="+tabNow == 3">
        <div class="title mb-1" style="grid-column: 1/-1;">
          分發醫院志願選填
        </div>
        <div v-for="(itemP, iP) in orderItems" :key="iP">
          <b-input-group :prepend="`${iP + 1}`" class="priority">
            <b-form-input
              :value="`${itemP.HospitalNo} ${itemP.HospitalName}`"
              readonly
            >
            </b-form-input>
          </b-input-group>
        </div>
      </main>
    </b-container>

    <template v-slot:modal-footer>
      <div class="w-100">
        <b-button
          variant="info"
          class="float-right ml-3 mr-3"
          @click="$bvModal.hide('ruralNursingDataGraduScoreAllWatch')"
          >關閉</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
import { store } from "@/store/global.js";
import moment from "moment";

export default {
  name: "EditPersonItem",
  data() {
    return {
      orderItems: [],
      item: {},
      variants: [
        "warning",
        "dark",
        "light",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
      ],
      highLevelScore: "",
      lowLevelScore: "",
      tabNow: "",
      score: "",
      maxScore: "",
      minScore: "",
      veteranStatus: "",
      identityCheck: "",
      veteranCheck: "",
      transcriptCheck: "",
    };
  },
  computed: {},
  methods: {
    async updateItem() {
      this.item = {};
      this.item = { ...store.editItem };

      this.tabNow = this.item.tab;
      const millitaryOptions = [
        { value: "A", text: "未服役" },
        { value: "B", text: "退伍" },
        { value: "C", text: "免役" },
        { value: "D", text: "女性免役" },
      ];
      this.item.VeteranStatus = millitaryOptions.find(
        (k) => k.value == this.item.VeteranStatus
      )
        ? millitaryOptions.find((k) => k.value == this.item.VeteranStatus).text
        : "";
      await this.getPersonData();
      if (+this.tabNow == 3) {
        await this.getScoreData();
        await this.getOrderData();
      }
    },
    doSometing() {
      this.$bvModal.hide("ruralNursingDataGraduScoreAllWatch");
    },
    async getPersonData() {
      const id = this.item.Identifier;
      const url = `StudentInfo/GetNurseFinish?$filter=Identifier eq '${id}'`;
      const oriSchool = this.item.SchoolName;

      let arrHome = await window.axios.get("SysCode/Get?category=country");
      arrHome = arrHome.map((s) => ({
        value: s.Code,
        text: s.Display,
      }));

      try {
        const { Items } = await window.axios.get(url);

        if (Items.length) {
          this.item = { ...this.item, ...Items[0] };

          this.item.gender = !Boolean(Items[0].Gender)
            ? ""
            : Items[0].Gender == "M"
            ? "男"
            : "女";

          this.item.home = arrHome.find((k) => k.value == Items[0].County)
            ? arrHome.find((k) => k.value == Items[0].County).text
            : "";
          this.item.SchoolName = oriSchool;
        }
      } catch (e) {}
    },
    async getScoreData() {
      const id = this.item.Identifier;
      const url = `NurseDistribution/GetGraduationResults?$filter=Identifier eq '${id}'`;
      const { Items } = await window.axios.get(url);
      const obj1 = JSON.parse(JSON.stringify(this.item));

      const obj2 = JSON.parse(JSON.stringify(Items[0]));

      this.item = {};
      this.item = { ...obj1, ...obj2 };

      const millitaryOptions = [
        { value: "A", text: "未服役" },
        { value: "B", text: "退伍" },
        { value: "C", text: "免役" },
        { value: "D", text: "女性免役" },
      ];
      this.item.VeteranStatus = millitaryOptions.find(
        (k) => k.value == this.item.VeteranStatus
      )
        ? millitaryOptions.find((k) => k.value == this.item.VeteranStatus).text
        : "";
    },
    async getOrderData() {
      const id = this.item.Identifier;
      const year = this.item.YearTerm;
      const url = `OrderHospital/GetOrders?sYearTerm=${year}&sIdentifier=${id}`;
      let { Hospitals } = await window.axios.get(url);
      Hospitals.sort(function(a, b) {
        return a.Priority - b.Priority;
      });
      this.orderItems = JSON.parse(JSON.stringify(Hospitals));
    },
  },
  mounted() {},
  watch: {},
};
</script>

<style scoped lang="scss">
p {
  font-size: 2em;
  text-align: center;
}
.input-group-text {
  width: 150px;
  background: #0379fd;
  color: #fff;
}
.priority {
  .input-group-prepend {
    .input-group-text {
      width: 50px;
    }
  }
}
div.input-group.special-col > div.input-group-prepend > div.input-group-text {
  width: 300px;
}

.code-dtc {
  display: grid;
  grid-template-columns: 60px minmax(400px, 525px);
}
.input-group {
  margin-bottom: 10px;
}
.main-grid-dtc,
.main-grid-dtc2 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 5px;
}
.main-grid-dtc2 {
  grid-template-columns: repeat(4, 1fr);
  .title {
    font-weight: 900;
  }
}

::v-deep
  #ruralNursingDataGraduScoreAllWatch
  > .modal-dialog
  > .modal-content
  > .modal-header {
  background: #7e2ca3;
  color: #ffffff;
  .close {
    color: #ffffff;
  }
}
::v-deep .modal-content {
  margin-left: -110px;
  width: 120%;
}
.form-control[readonly] {
  background-color: #ffffff;
}
hr.new {
  border-top: 1px dashed #cacaca;
}
</style>
